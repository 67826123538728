const rootconfig = {
  root: null,
  rootMargin: '1050px',
  threshold: 0.5
};

// Create a new observer
const observer = new IntersectionObserver(entries => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      entry.target.classList.add('ContentGroup--fadeUp--active');
      observer.unobserve(entry.target);
    }
  }, rootconfig);
});

// The elements to observe
const observableElements = document.querySelectorAll(`[data-effect='fade-up']`);

// Attach them to the observer
if (observableElements.length > 0) {
  observableElements.forEach(element => observer.observe(element));
}


// Observer for new homepage for ellipse
const observerEllipse = new IntersectionObserver(entries => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      entry.target.classList.add('Decor-ellipse--show');
      observerEllipse.unobserve(entry.target);
    }
  }, rootconfig);
});

// The elements to observe
const observableElementsEllipse = document.querySelectorAll('.Decor-ellipse');

// Attach them to the observer
if (observableElementsEllipse.length > 0) {
  observableElementsEllipse.forEach(element => observerEllipse.observe(element));
}

// Observer for new homepage for lines
const observerLine = new IntersectionObserver(entries => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      entry.target.classList.add('Decor-animationAllLines--Show');
    }
    else { 
      entry.target.classList.remove('Decor-animationAllLines--Show'); 
    }
  }, rootconfig);

});

// The elements to observe
const observableElementsLine = document.querySelectorAll('.Decor-animationAllLines');

// Attach them to the observer
if (observableElementsLine.length > 0) {
  observableElementsLine.forEach(element => observerLine.observe(element));
}


