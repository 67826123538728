function sendMessageToNeedHelp(formData, callback) {
  $.ajax({
    url: '/public/need_help_contacts?locale=en',
    type: 'POST',
    processData: false,
    contentType: false,
    data: formData,
    success: callback
  });
}

$(document).ready(function () {
  const openButton = $('#open_need_help_button');
  const form = $('#need_help_new_contact_form');
  const formContainer = $('#need_help_form_container');
  const thankYouMessage = $('#need_help_thank_you_message');
  const dialog = $('.NeedHelp .Dialog');

  function closeDialog () {
    dialog.attr('aria-hidden', 'true');
    dialog.removeClass('is-active');
    $('body').css('overflow', 'visible');
  };

  function openThankYouMessage () {
    formContainer.hide();
    thankYouMessage.show();
    form[0].reset();

    setTimeout(function () {
      closeDialog();

      thankYouMessage.hide();
      formContainer.show();
    }, 3000);
  }

  openButton.on('click', function() {
    const isMobile = window.screen.width < 768;

    if(!isMobile) {
      $('body').css('overflow', 'visible');
    }
  });

  form.on('submit', function(e) {
    e.preventDefault();
    const formData = new FormData(this);

    sendMessageToNeedHelp(formData, openThankYouMessage);
  });
});

