import whenDomReady from 'when-dom-ready';

const initializeMenu = () => {
  const subMenuContainers = document.getElementsByClassName('jsNavigation');

  if (!subMenuContainers.length) {
    throw new Error('Header menu is missing');
  }

  Array.from(subMenuContainers).forEach((subContainer) => {
    subContainer.style.display = 'block';
  });
};

const shouldContinueScroll = (scrollTop) => {
  const body = document.querySelector('body');
  const stopScroll = body.scrollHeight;
  return scrollTop + window.innerHeight < stopScroll;
};

const onScrollMenuHandler = () => {
  let lastScrollTop = 0;
  const header = document.querySelector('.Header');

  const handleScroll = () => {
    const headerHeight = header.offsetHeight;
    const scrollTop = window.scrollY;

    if (!shouldContinueScroll(scrollTop)) {
      return;
    }

    if (scrollTop > headerHeight) {
      header.classList.add('floating');
    }

    if (scrollTop > lastScrollTop) {
      handleScrollDown(header, scrollTop, headerHeight);
    } else {
      handleScrollUp(header, scrollTop, headerHeight);
    }
    lastScrollTop = scrollTop;
  };

  window.addEventListener('scroll', handleScroll, false);
};

const handleScrollDown = (header, scrollTop, headerHeight) => {
  if (header.classList.contains('floating')) {
    if (header.style.position === 'fixed') {
      header.style.top = `${scrollTop}px`;
      header.style.position = 'absolute';
    }

    const headerTop = parseInt(header.style.top, 10);

    if (scrollTop > headerTop + headerHeight) {
      const openDropdowns = document.querySelectorAll('.jsHoverTrack.active');

      openDropdowns.forEach((dropdown) => {
        dropdown.classList.remove('active');
      });
    }
  }
};

const handleScrollUp = (header, scrollTop, headerHeight) => {
  if (header.classList.contains('floating')) {
    if (scrollTop <= 0) {
      header.classList.remove('floating');
      header.style.top = 0;
      header.style.position = 'absolute';
    } else {
      const headerTop = parseInt(header.style.top, 10);

      if (header.style.position === 'absolute') {
        if (headerTop < scrollTop - headerHeight) {
          header.style.top = `${scrollTop - headerHeight}px`;
        } else if (headerTop >= scrollTop) {
          header.style.top = 0;
          header.style.position = 'fixed';
        }
      }
    }
  }
};


const deactivateSubmenu = () => {
  const activeDropdown = document.querySelector('.jsHoverTrack.active');
  if (activeDropdown) {
    activeDropdown.classList.remove('active');
  }
};

const handleMouseEvents = (dropdown, index) => {
  let mouseOver = false;

  dropdown.addEventListener('mouseenter', (e) => {
    mouseOver = true;
    const target = e.currentTarget;

    setTimeout(() => {
      if (mouseOver) {
        if (index === 0) { target.style.paddingLeft = '80px'; }
        deactivateSubmenu();

        target.classList.add('active');
        const image = dropdown.querySelector('.jsImageLoad');
        image.style.display = 'block';
      }
    }, 200);
  });

  dropdown.addEventListener('mouseleave', (e) => {
    mouseOver = false;
    if (index === 0) { e.currentTarget.style.paddingLeft = "18px"; }
  });
}

const handleKeyPress = (dropdown) => {
  dropdown.addEventListener('keypress', (e) => {
    e.currentTarget.classList.toggle('active');

    const image = e.currentTarget.querySelector('.jsImageLoad');
    image.style.display = 'block';
  });
};

const handleFocusOut = (dropdown) => {
  dropdown.addEventListener('focusout', (e) => {
    if (e.relatedTarget && e.relatedTarget.closest('.jsHoverTrack.active') === null) {
      e.currentTarget.classList.remove('active');
    }
  });
};

const closeSubmenuOnClick = () => {
  Array.from(document.querySelector('header').querySelectorAll('.jsNavigation a')).forEach((link) => {
    link.addEventListener('click', () => {
      const openedDropdown = document.querySelector('.jsHoverTrack.active .jsNavigation');
      openedDropdown.style.display = 'none';
      setTimeout(() => {
        openedDropdown.style.display = 'block';
      }, 100);
    });
  });
};

const desktopMenuHandler = () => {
  const dropdowns = document.getElementsByClassName('jsHoverTrack');
  const menuList = document.getElementsByClassName('jsMouseleaveTarget')[0];

  Array.from(dropdowns).forEach((dropdown, index) => {
    handleMouseEvents(dropdown, index);
    handleKeyPress(dropdown);
    handleFocusOut(dropdown);
  });

  menuList.addEventListener('mouseleave', deactivateSubmenu);
  closeSubmenuOnClick();
};

const mobileMenuHandler = () => {
  const getElements = () => ({
    burgerButton: document.querySelector('.jsOpenButton'),
    mobileMenu: document.querySelector('.jsMobileMenuOpen'),
    html: document.querySelector('html'),
    shadow: document.querySelector('.jsToggleShadow'),
    anchorLinks: document.querySelectorAll('.jsMobileMenuOpen a'),
    header: document.querySelector('.Header'),
    dropdowns: document.querySelectorAll('.jsToggleTracker')
  });

  const elements = getElements();

  const handleClickOutside = (e) => {
    const target = e.target;

    if (!elements.mobileMenu.contains(target) && !elements.burgerButton.contains(target)) {
      toggleMenu(false);
      closeDropdowns();
    }
  };

  const toggleMenu = (force = undefined) => {
    elements.mobileMenu.classList.toggle('opened', force);
    elements.burgerButton.classList.toggle('opened', force);
    elements.shadow.classList.toggle('active', force);
    elements.header.classList.toggle('mobileMenuOpened', force);
    return elements.html.classList.toggle('Header-mobileMenuOpened', force);
  };

  const closeDropdowns = () => {
    elements.dropdowns.forEach((dropdown) => {
      dropdown.classList.remove('active');
      document.getElementById(dropdown.dataset.toggleTarget).classList.remove('active');
    });
  };

  const bindEvents = () => {
    elements.anchorLinks.forEach((link) => {
      link.addEventListener('click', () => {
        toggleMenu(false);
        closeDropdowns();
      });
    });

    elements.burgerButton.addEventListener('click', () => {
      const opened = toggleMenu();

      if (opened) {
        elements.header.style.position = 'fixed';
        elements.header.style.top = 0;
        document.addEventListener('click', handleClickOutside);
      } else {
        document.removeEventListener('click', handleClickOutside);
        closeDropdowns();
      }
    });

    elements.dropdowns.forEach((dropdown) => {
      dropdown.addEventListener('click', (e) => {
        const target = e.currentTarget;
        const activeDropdown = document.querySelector('.jsToggleTracker.active');

        if (activeDropdown && activeDropdown !== target) {
          activeDropdown.classList.remove('active');
          document.getElementById(activeDropdown.dataset.toggleTarget).classList.remove('active');
        }

        target.classList.toggle('active');
        document.getElementById(target.dataset.toggleTarget).classList.toggle('active');
      });
    });

    window.addEventListener('resize', () => {
      if (!elements.mobileMenu.classList.contains('opened')) {
        elements.header.classList.remove('floating');
        elements.header.style.top = 0;
        elements.header.style.position = 'absolute';
      }

      if (window.innerWidth > 1200) {
        toggleMenu(false);
        closeDropdowns();
      }
    });
  };

  bindEvents();
};

whenDomReady()
  .then(initializeMenu)
  .then(onScrollMenuHandler)
  .then(desktopMenuHandler)
  .then(mobileMenuHandler)
  .catch(err => console.log(err))
